.card-padding-0 > .ant-card-body {
	padding: 0;
}

.underline:hover {
	text-decoration: underline;
}

/* .bundle-price-view { */
	/* display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 1em; */
/* } */

.bundle-children {
	border-left: 2px solid #e8e8e8;
	/* border-left: 2px solid #5c5c5c; */
}

.top-guide {
	height: 2.65em;
	width: 1.5em;
	border-left: 2px solid lightgray;
	border-bottom: 2px solid lightgray;
}

.bottom-guide {
	height: calc(100% - 2.65em);
	width: 1.5em;
	border-left: 2px solid lightgray;
}

.content-count > *:last-child {
	display: none;
}

.horiz-divider {
	font-size: 18px;
	color: #353535;
}
.searchbox .ant-input {
	border-radius: 0 !important;
}

.custom-current-refinement-container .refinement-tag {
	padding: 6px;
	color: #ffffff;
}
.custom-current-refinement-container .refinement-tag .ant-tag-close-icon {
	color: #ffffff;
}
.product-card > .ant-card-body {
	height: 100%;
}

.gx-algolia-refinementList li.ais-RefinementList-item {
	margin-bottom: 0.8em !important;
}

.gx-algolia-category-title {
	font-size: 1rem;
}

.gx-algolia-refinementList .ais-RefinementList-labelText {
	white-space: normal;
}

.product-grid {
	display: grid;
	width: 100%;
	/* grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	grid-template-rows: auto; */
	/* column-gap: 1.75em; */
	row-gap: 1.75em;
}

.selection-result-wrapper .product-grid {
	display: block;
}

.filter-divider {
	margin-bottom: 0.9rem;
}

.buy-btn-content {
	min-width: 7rem;
}

.product-price-details .teaser-tag {
	background-color: rgb(250, 250, 250);
	border-radius: 5px;
    padding: 0.5em 1em;
    color: #353535;
    fill: #353535;
    font-size: 0.7rem;
}

.product-list-wrapper .card .bottom-price-bar {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 8px;
	min-height: 35px;
	color: #ffffff;
}

.refinements-filter-wrapper {
	width: 20em;
}
.refinements-filter-wrapper .filter-header {
	background-color: #ffffff;
	border: 1px solid rgb(217, 217, 217);
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    min-width: 20em;
}
.refinements-filter-wrapper .filter-header-active {
	background-color: #f5f5f5;
}
.refinements-filter-wrapper .filter-header .ant-badge {
	margin-bottom: 0px;
	margin-right: 0px;
}
.refinements-filter-wrapper .filter-header .button-secondary {
	margin-right: 5px;
	margin-left: 5px;
}
.refinements-filter-body-wrapper {
	background-color: #f5f5f5;
	border-radius: 1px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.refinements-filter-body-wrapper .gx-algolia-sidebar {
	border-radius: 1px !important;
	margin-bottom: 0px;
	background-color: #f5f5f5;
	padding: 15px !important;
}
.refinements-filter-body-wrapper .ant-dropdown-menu-item {
	padding: 0px;
}
.cart-display-wrapper .gx-algolia-sidebar {
	width: 380px !important;
}